import React from "react";
import {
  Avatar,
  Button,
  Container,
  FormHelperText,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Form, Formik } from "formik";
import { LockOutlined } from "@material-ui/icons";
import { Link as RouterLink, Redirect } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";

import { FormInput } from "../../components";
import { LoginFormValuesType } from "./types";
import { LOGIN_VALIDATION_SCHEMA } from "./constants";
import {
  RootState,
  login,
  selectLoading,
  selectError,
  ActionTypes,
  selectIsAuth,
} from "../../store";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Login: React.FC<PropsFromRedux> = ({
  loading,
  isAuth,
  login,
  error,
}) => {
  const classes = useStyles();

  if (isAuth) {
    return <Redirect to={"/profile"} />;
  }

  const handleLogin = (loginData: LoginFormValuesType) => {
    login(loginData);
  };

  return (
    <Container component={"main"} maxWidth={"xs"}>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlined />
        </Avatar>
        <Typography component={"h1"} variant={"h5"}>
          Sign In
        </Typography>
      </div>
      <Formik
        validationSchema={LOGIN_VALIDATION_SCHEMA}
        initialValues={{ email: "", password: "" }}
        onSubmit={handleLogin}
      >
        <Form>
          <FormInput
            id={"email"}
            name={"email"}
            label={"Email Address"}
            autoComplete="email"
            required
            margin="normal"
            variant="outlined"
            fullWidth
          />
          <FormInput
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormHelperText error={!!error}>{error}</FormHelperText>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={loading}
          >
            Sign In
          </Button>
        </Form>
      </Formik>
      <Grid container>
        <Grid item xs>
          <Link component={RouterLink} to={"/forgot-password"}>
            Forgot password?
          </Link>
        </Grid>
        <Grid item>
          <Link component={RouterLink} to={"/signup"}>
            Don't have an account? Sign Up
          </Link>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapsStateToProps = (state: RootState) => ({
  loading: selectLoading([ActionTypes.LOGIN])(state),
  error: selectError([ActionTypes.LOGIN])(state),
  isAuth: selectIsAuth(state),
});

const connector = connect(mapsStateToProps, { login });

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Login);
