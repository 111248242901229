import { createSelector } from "reselect";

import { RootState } from "../index";

const joinSlice = (state: RootState) => state.join;

export const selectJoined = createSelector(joinSlice, (join) => join.joined);

export const selectJoinLinkInfo = createSelector(
  joinSlice,
  (join) => join.joinLinkInfo
);

export const selectJoinLinkBusinessName = createSelector(
    selectJoinLinkInfo,
    (joinLinkInfo) => joinLinkInfo?.businessName
)

export const selectTicket = createSelector(joinSlice, (join) => join.ticket);
