import { AnyAction } from "@reduxjs/toolkit";

export interface ErrorSliceType {
  [key: string]: string | void;
}

const initialState: ErrorSliceType = {};

const error = (state = initialState, action: AnyAction) => {
  const matches = /(.+)\/(pending|fulfilled|rejected|reset)/.exec(action.type);

  if (!matches) {
    return state;
  }

  const [, requestName, requestType] = matches;

  return {
    ...state,
    [requestName]: requestType === "rejected" ? action.payload : "",
  };
};

export default error;
