import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Form, Formik } from "formik";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormHelperText,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";

import {
  ActionTypes,
  RootState,
  selectError,
  selectLoading,
  updateCredentials,
} from "../../store";
import { CREDENTIALS_VALIDATION_SCHEMA } from "./constants";
import { FormInput } from "../form-input";
import { CredentialsFormValuesType } from "./types";
import {BusinessProfileType} from "../../types";

const useStyles = makeStyles((theme) => ({
  dataTitle: {
    ...theme.typography["subtitle2"],
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

interface Props extends PropsFromRedux {
  profile?: BusinessProfileType;
}

const CredentialsForm: React.FC<Props> = ({
  profile,
  updateCredentials,
  loading,
  error,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (!loading && !error) {
      setIsEditing(false);
    }
  }, [loading, error]);

  const handleSave = ({ password, email }: CredentialsFormValuesType) => {
    updateCredentials({ email, password });
  };

  const handleEdit = () => {
    setIsEditing(!isEditing);
  };

  if (isEditing) {
    return (
      <div className={classes.paper}>
        <Formik
          validationSchema={CREDENTIALS_VALIDATION_SCHEMA}
          initialValues={{
            email: profile?.email || "",
            password: "",
            confirmationPassword: "",
          }}
          onSubmit={handleSave}
        >
          <Form>
            <Card>
              <CardHeader
                title={"Credentials"}
                action={
                  <IconButton onClick={handleEdit} aria-label="edit">
                    <CloseIcon />
                  </IconButton>
                }
              />
              <CardContent>
                <Grid container direction={"column"} spacing={3} md={6} xs={12}>
                  <Grid item>
                    <FormInput
                      id={"email"}
                      name={"email"}
                      label={"Email"}
                      autoComplete={"email"}
                      margin="dense"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <FormInput
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      placeholder={"You can leave it blank"}
                      name="password"
                      label="New password"
                      type="password"
                      id="password"
                    />
                  </Grid>
                  <Grid item>
                    <FormInput
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      placeholder={"You can leave it blank"}
                      name="confirmationPassword"
                      label="Confirm password"
                      type="password"
                      id="confirmationPassword"
                    />
                  </Grid>
                </Grid>
                <FormHelperText error={!!error}>{error}</FormHelperText>
              </CardContent>
              <CardActions>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  className={classes.submit}
                  disabled={loading}
                >
                  Save
                </Button>
              </CardActions>
            </Card>
          </Form>
        </Formik>
      </div>
    );
  }

  return (
    <div className={classes.paper}>
      <Card>
        <CardHeader
          title={"Credentials"}
          action={
            <IconButton onClick={handleEdit} aria-label="edit">
              <EditIcon />
            </IconButton>
          }
        />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Typography
                className={classes.dataTitle}
                variant={"h6"}
                component={"h6"}
              >
                Email
              </Typography>
              <span>{profile?.email}</span>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography
                className={classes.dataTitle}
                variant={"h6"}
                component={"h6"}
              >
                Password
              </Typography>
              <span>*****</span>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  error: selectError([ActionTypes.UPDATE_PROFILE_CREDENTIALS])(state),
  loading: selectLoading([ActionTypes.UPDATE_PROFILE_CREDENTIALS])(state),
});

const connector = connect(mapStateToProps, { updateCredentials });

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CredentialsForm);
