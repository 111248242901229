import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { auth } from "../firebase";
import { initAuth, RootState, selectIsAuthLoaded } from "../store";
import { CenteredLoader } from "../components";

const AuthWrapper: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const authLoaded = useSelector((state: RootState) =>
    selectIsAuthLoaded(state)
  );

  useEffect(() => {
    return auth.onAuthStateChanged((user) => {
      dispatch(initAuth(user?.uid));
    });
  }, [dispatch]);

  if (!authLoaded) {
    return <CenteredLoader />;
  }

  return <>{children}</>;
};

export default AuthWrapper;
