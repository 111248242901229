import React from "react";
import { AppBar, IconButton, Toolbar } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

interface Props {
  handleDrawerToggle: () => void;
}

const Header: React.FC<Props> = ({ handleDrawerToggle }) => (
  <AppBar position="sticky">
    <Toolbar>
      <IconButton
        onClick={handleDrawerToggle}
        color="inherit"
        aria-label="open menu"
        edge="start"
      >
        <MenuIcon />
      </IconButton>
    </Toolbar>
  </AppBar>
);

export default Header;
