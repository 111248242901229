import { createSelector } from "reselect";

import { RootState } from "../index";

const authSlice = (state: RootState) => state.auth;

export const selectIsAuth = createSelector(authSlice, (auth) => auth.isAuth);

export const selectIsAuthLoaded = createSelector(
  authSlice,
  (auth) => auth.authLoaded
);

export const selectProfile = createSelector(authSlice, (auth) => auth.profile);
export const selectBusinessName = createSelector(selectProfile, (profile) => profile?.name)
export const selectLineSettings = createSelector(authSlice, (auth) => auth.lineSettings);
