import React from "react";
import { CardHeader, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

interface Props {
  status: string;
  handleEdit: () => void;
}

const Header: React.FC<Props> = ({ status, handleEdit }) => {
  const getTitle = () => {
    if (status === "invited") return "It's your turn. Please take a seat.";
    if (status === "canceled") return "You left the line.";
    if (status === "confirmed") return "Invitation Confirmed.";

    return "Your Contact Details";
  };

  const getSubheader = () => {
    if (status === "pending")
      return "Please make sure it’s correct. We’ll send you a notification when it’s your turn.";

    return null;
  };

  const getAction = () => {
    if (status === "pending")
      return (
        <IconButton onClick={handleEdit} aria-label="edit">
          <EditIcon />
        </IconButton>
      );

    return null;
  };

  return (
    <CardHeader
      title={getTitle()}
      subheader={getSubheader()}
      action={getAction()}
    />
  );
};

export default Header;
