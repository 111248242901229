import { createAsyncThunk } from "@reduxjs/toolkit";
import firebase from "firebase/app";

import { ActionTypes } from "../action-types";
import { firestore } from "../../firebase";

export const invite = createAsyncThunk<undefined, string>(
  ActionTypes.INVITE,
  async (ticketId, { rejectWithValue }) => {
    const data = {
      status: "invited",
      invitedAt: firebase.firestore.Timestamp.now(),
    };
    try {
      const batch = firestore.batch();

      batch.set(firestore.doc(`tickets/${ticketId}`), data, { merge: true });

      batch.set(
        firestore.doc(`tickets/${ticketId}/public_ticket/${ticketId}`),
        data,
        { merge: true }
      );

      await batch.commit();
    } catch (err) {
      return rejectWithValue(
        err.response.data?.error ||
          "Something went wrong. Please try again later."
      );
    }
  }
);
