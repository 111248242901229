import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import {
  getLineSettings,
  initAuth,
  login,
  logout,
  signUp,
  updateCredentials,
  updateProfileInfo,
  updateLineSettings,
} from "../actions";
import { BusinessProfileType, LineSettingsType } from "../../types";

type AuthSliceType = {
  isAuth: boolean;
  authLoaded: boolean;
  profile?: BusinessProfileType;
  lineSettings?: LineSettingsType;
};

const initialState: AuthSliceType = {
  isAuth: false,
  authLoaded: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsAuth: (state, action) => {
      state.isAuth = action.payload;
    },
    resetIsAuth: (state) => {
      state.isAuth = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state: AuthSliceType, action) => {
      state.isAuth = true;
      state.profile = action.payload;
    });
    builder.addCase(logout.fulfilled, (state: AuthSliceType) => {
      state.isAuth = false;
      state.profile = undefined;
    });
    builder.addCase(signUp.fulfilled, (state: AuthSliceType, action) => {
      state.isAuth = true;
      state.profile = action.payload;
    });
    builder.addCase(initAuth.fulfilled, (state: AuthSliceType, action) => {
      if (action.payload) {
        state.profile = action.payload;
        state.isAuth = true;
      }
      state.authLoaded = true;
    });
    builder.addCase(
      updateProfileInfo.fulfilled,
      (state: AuthSliceType, action) => {
        state.profile = _.merge(state.profile, action.payload);
      }
    );
    builder.addCase(
      updateCredentials.fulfilled,
      (state: AuthSliceType, action) => {
        state.profile = _.merge(state.profile, action.payload);
      }
    );
    builder.addCase(
      updateLineSettings.fulfilled,
      (state: AuthSliceType, action) => {
        state.lineSettings = _.merge(state.lineSettings, action.payload);
      }
    );
    builder.addCase(
      getLineSettings.fulfilled,
      (state: AuthSliceType, action) => {
        state.lineSettings = action.payload;
      }
    );
  },
});

export const { setIsAuth, resetIsAuth } = authSlice.actions;

export default authSlice.reducer;
