import React, { useEffect } from "react";
import { Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Redirect } from "react-router-dom";

import { CenteredLoader, Error, JoinForm } from "../../components";
import {
  ActionTypes,
  getJoinLinkInfo,
  RootState,
  selectError,
  selectJoined,
  selectJoinLinkBusinessName,
  selectLoading,
} from "../../store";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(4),
  },
  paper: {
    marginTop: theme.spacing(8),
  },
  error: {
    textAlign: "center",
  },
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)",
  },
}));

const Join: React.FC<PropsFromRedux & RouteComponentProps<{ lineId: string }>> =
  ({
    businessName,
    loading,
    match: {
      params: { lineId },
    },
    location: { pathname },
    joined,
    getJoinLinkInfo,
    error,
  }) => {
    const classes = useStyles();

    useEffect(() => {
      getJoinLinkInfo(lineId);
    }, [getJoinLinkInfo, lineId]);

    if (error) return <Error error={error} />;

    if (joined && businessName) {
      return (
        <Redirect to={`${pathname}/${localStorage.getItem("ticketId")}`} />
      );
    }

    if (loading || !businessName) {
      return <CenteredLoader />;
    }

    return (
      <Container component={"main"} maxWidth={"md"}>
        <div className={classes.paper}>
          <Typography className={classes.title} variant={"h5"} component={"h1"}>
            Get in line to {businessName}
          </Typography>
          <JoinForm lineId={lineId} />
        </div>
      </Container>
    );
  };

const mapsStateToProps = (state: RootState) => {
  return {
    joined: selectJoined(state),
    businessName: selectJoinLinkBusinessName(state),
    loading: selectLoading([ActionTypes.JOIN_LINE])(state),
    error: selectError([ActionTypes.GET_JOIN_LINK_INFO])(state),
  };
};

const connector = connect(mapsStateToProps, { getJoinLinkInfo });

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Join);
