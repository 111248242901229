import { createSelector } from "reselect";
import _ from "lodash";

import { ActionTypes, RootState } from "../index";
import { NotificationSliceType } from "../reducers/notification";

const notificationSelector = (state: RootState) => state.notification;

export const selectNotification = (actions: ActionTypes[]) =>
  createSelector(
    notificationSelector,
    (notification: NotificationSliceType) =>
      _(actions)
        .map((action) => notification[action])
        .compact()
        .first() || ""
  );
