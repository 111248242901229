import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import { connect, ConnectedProps } from "react-redux";

import {
  ActionTypes,
  RootState,
  selectError,
  selectLoading,
  updateProfileInfo,
} from "../../../store";
import { ProfileForm } from "./profile-form";
import { BusinessProfileType } from "../../../types";
import { ProfileFormValuesType } from "./profile-form/types";

const useStyles = makeStyles((theme) => ({
  dataTitle: {
    ...theme.typography["subtitle2"],
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
  },
}));

interface Props extends PropsFromRedux {
  profile?: BusinessProfileType;
}

const ProfileInfo: React.FC<Props> = ({
  profile,
  updateProfileInfo,
  loading,
  error,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const classes = useStyles();

  const handleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleSave = (values: ProfileFormValuesType) => {
    updateProfileInfo(values).then(() => setIsEditing(false));
  };

  if (isEditing) {
    return (
      <ProfileForm
        error={error}
        loading={loading}
        profile={profile}
        handleEdit={handleEdit}
        handleSave={handleSave}
      />
    );
  }

  return (
    <div className={classes.paper}>
      <Card>
        <CardHeader
          title={"Profile Info"}
          action={
            <IconButton onClick={handleEdit} aria-label="edit">
              <EditIcon />
            </IconButton>
          }
        />
        <CardContent>
          <Grid container spacing={1}>
            <Grid
              container
              direction={"column"}
              spacing={1}
              item
              md={6}
              xs={12}
            >
              <Grid item>
                <Typography
                  className={classes.dataTitle}
                  variant={"h6"}
                  component={"h6"}
                >
                  Business name
                </Typography>
                <span>{profile?.name}</span>
              </Grid>

              <Grid item>
                <Typography
                  className={classes.dataTitle}
                  variant={"h6"}
                  component={"h6"}
                >
                  Phone
                </Typography>
                <span>{profile?.phone}</span>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction={"column"}
              md={6}
              xs={12}
              spacing={1}
            >
              <Grid item>
                <Typography
                  className={classes.dataTitle}
                  variant={"h6"}
                  component={"h6"}
                >
                  Country
                </Typography>
                <div>{profile?.address.country}</div>
              </Grid>

              <Grid item>
                <Typography
                  className={classes.dataTitle}
                  variant={"h6"}
                  component={"h6"}
                >
                  Address
                </Typography>
                <div>{profile?.address.address}</div>
                <div>{profile?.address["address-2"]}</div>
              </Grid>

              <Grid container item justify={"space-between"} spacing={1}>
                <Grid item xs={12} sm={4}>
                  <Typography
                    className={classes.dataTitle}
                    variant={"h6"}
                    component={"h6"}
                  >
                    City
                  </Typography>
                  <div>{profile?.address.city}</div>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Typography
                    className={classes.dataTitle}
                    variant={"h6"}
                    component={"h6"}
                  >
                    State
                  </Typography>
                  <div>{profile?.address.state}</div>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Typography
                    className={classes.dataTitle}
                    variant={"h6"}
                    component={"h6"}
                  >
                    Zip Code
                  </Typography>
                  <div>{profile?.address.zip}</div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: selectLoading([ActionTypes.UPDATE_PROFILE_INFO])(state),
  error: selectError([ActionTypes.UPDATE_PROFILE_INFO])(state),
});

const connector = connect(mapStateToProps, { updateProfileInfo });

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ProfileInfo);
