import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

interface Props {
  error: string;
}

const useStyles = makeStyles(() => ({
  error: {
    textAlign: "center",
  },
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)",
  },
}));

const Error: React.FC<Props> = ({ error }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.error} variant={"h5"} component={"h1"}>
          {error}
      </Typography>
    </div>
  );
};

export default Error;
