import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Container,
  FormHelperText,
  Grid,
  Link,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { LockOutlined } from "@material-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import { Alert } from "@material-ui/lab";

import { FormInput } from "../../components";
import { FORGOT_PASSWORD_VALIDATION_SCHEMA } from "./constants";
import { ForgotPasswordFormValuesType } from "./types";
import {
  ActionTypes,
  resetPassword,
  resetState,
  RootState,
  selectError,
  selectLoading,
  selectNotification,
} from "../../store";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ForgotPassword: React.FC = () => {
  const [notificationOpened, setNotificationOpened] = useState(false);

  const loading = useSelector<RootState, boolean>((state) =>
    selectLoading([ActionTypes.RESET_PASSWORD])(state)
  );
  const error = useSelector<RootState, string>((state) =>
    selectError([ActionTypes.RESET_PASSWORD])(state)
  );
  const notification = useSelector<RootState, string>((state) =>
    selectNotification([ActionTypes.RESET_PASSWORD])(state)
  );

  const dispatch = useDispatch();

  const classes = useStyles();

  useEffect(() => {
    setNotificationOpened(!!notification);
  }, [notification]);

  useEffect(() => {
    return () => {
      dispatch(resetState(ActionTypes.RESET_PASSWORD));
    };
  }, [dispatch]);

  const handleSubmit = (values: ForgotPasswordFormValuesType) => {
    dispatch(resetPassword(values));
  };

  const handleCloseNotification = (
    _: React.SyntheticEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setNotificationOpened(false);
  };

  return (
    <Container component={"main"} maxWidth={"xs"}>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlined />
        </Avatar>
        <Typography component={"h1"} variant={"h5"}>
          Forgot Password
        </Typography>
      </div>
      <Formik
        initialValues={{ email: "" }}
        validationSchema={FORGOT_PASSWORD_VALIDATION_SCHEMA}
        onSubmit={handleSubmit}
      >
        <Form>
          <FormInput
            id={"email"}
            name={"email"}
            label={"Email Address"}
            autoComplete="email"
            required
            margin="normal"
            variant="outlined"
            fullWidth
          />
          <FormHelperText error={!!error}>{error}</FormHelperText>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={loading}
          >
            Reset Password
          </Button>
        </Form>
      </Formik>
      {notification && (
        <Snackbar open={notificationOpened} onClose={handleCloseNotification}>
          <Alert onClose={handleCloseNotification} severity="info">
            {notification}
          </Alert>
        </Snackbar>
      )}
      <Grid container>
        <Grid item xs>
          <Link component={RouterLink} to={"/login"}>
            Have an account? Sign In
          </Link>
        </Grid>
        <Grid item>
          <Link component={RouterLink} to={"/signup"}>
            Don't have an account? Sign Up
          </Link>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ForgotPassword;
