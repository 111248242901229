import React from "react";
import { useField, useFormikContext } from "formik";
import { TextField } from "@material-ui/core";
import { TextFieldProps } from "@material-ui/core/TextField/TextField";
import { MaterialUiPhoneNumberProps } from "material-ui-phone-number";

interface Props {
  comp?: React.ComponentType<TextFieldProps>;
  name: string;
}

const FormInput: React.FC<TextFieldProps & Props & MaterialUiPhoneNumberProps> =
  (props) => {
    const [field, meta] = useField(props.name);
    const { setFieldValue } = useFormikContext();

    if (props.comp) {
      const Comp = props.comp;

      return (
        <Comp
          {...props}
          {...field}
          onChange={(res) => {
            setFieldValue(field.name, res);
          }}
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error}
        />
      );
    }

    return (
      <TextField
        {...props}
        {...field}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
      />
    );
  };

export default FormInput;
