import React from "react";
import { Redirect, Route } from "react-router-dom";
import { RouteProps } from "react-router";
import { useSelector } from "react-redux";

import { selectIsAuth } from "../../store";

const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const auth = useSelector(selectIsAuth);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
