import * as Yup from "yup";
import "yup-phone";

export const CREDENTIALS_VALIDATION_SCHEMA = Yup.object({
  email: Yup.string().email("Invalid email address").required("Required"),
  password: Yup.string()
    .min(6, "Must be 6 at least 6 characters")
    .max(20, "Must be 20 characters or less"),
  confirmationPassword: Yup.string().when("password", {
    is: (val: string) => !!val,
    then: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords should match")
      .required("Required"),
  }),
});
