import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ScheduleIcon from "@material-ui/icons/Schedule";

import { TicketInfoType } from "../../../store/reducers/join";
import { convertTicketSnapshot } from "../../../utils/utils";

import { firestore } from "../../../firebase";
import { getMyTicket, getTicketWaitingTime } from "./utils";

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(4),
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  notification: {
    marginBottom: theme.spacing(4),
  },
}));

interface Props {
  lineId: string;
  ticketId: string;
}

const LineTable: React.FC<Props> = ({ ticketId, lineId }) => {
  const classes = useStyles();
  const [tickets, setTickets] = useState<Array<TicketInfoType>>([]);
  const [error, setError] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);

  const [, setTime] = useState<number>();

  const handleLoaded = useCallback(() => {
    if (!isLoaded) {
      setIsLoaded(true);
    }
  }, [isLoaded]);

  useEffect(() => {
    return firestore
      .collectionGroup("public_ticket")
      .where("lineId", "==", lineId)
      .where("status", "==", "pending")
      .orderBy("createdAt")
      .onSnapshot(
        (querySnapshot) => {
          setTickets(querySnapshot.docs.map(convertTicketSnapshot));

          handleLoaded();
        },
        () => {
          setError("An  error occurred. Please try again later.");
          handleLoaded();
        }
      );
  }, [lineId, handleLoaded]);

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1000 * 20);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const myTicketIndex = useMemo(
    () => getMyTicket(tickets, ticketId),
    [tickets, ticketId]
  );

  if (error) {
    return <div>Error</div>;
  }

  if (!isLoaded) {
    return (
      <Box textAlign={"center"}>
        <CircularProgress />
      </Box>
    );
  }

  const renderEstimatedWaitingTime = (ticket: TicketInfoType) => {
    if (!ticket.estimatedWaitingTime) {
      return <CircularProgress />;
    }

    const resultEstimatedWaitingTime =
      ticket.estimatedWaitingTime - getTicketWaitingTime(ticket);

    if (resultEstimatedWaitingTime <= 0) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <ScheduleIcon /> <div style={{ marginLeft: "5px" }}>Elapsed</div>
        </div>
      );
    }

    return resultEstimatedWaitingTime;
  };

  const renderHeader = () => {
    const resultEstimatedWaitingTime =
      tickets[myTicketIndex]?.estimatedWaitingTime -
      getTicketWaitingTime(tickets[myTicketIndex]);

    if (resultEstimatedWaitingTime <= 0) {
      return (
        <Typography
          className={classes.notification}
          variant={"h5"}
          component={"h1"}
        >
          It is taking longer than expected to invite you, please be patient.
        </Typography>
      );
    }

    return null;
  };

  return (
    <div className={classes.container}>
      {renderHeader()}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align={"right"}>Number in Line</TableCell>
              <TableCell align={"right"}>Estimated Wait Time (Mins)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tickets.map((ticket, i) => (
              <TableRow key={ticket.id}>
                <TableCell>
                  {ticket.id === ticketId ? (
                    <span className={classes.bold}>You</span>
                  ) : (
                    ticket.firstName + ticket.lastName
                  )}
                </TableCell>
                <TableCell align="right">{i + 1}</TableCell>
                <TableCell align="right">
                  {renderEstimatedWaitingTime(ticket)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default LineTable;
