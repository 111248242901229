import React, { useCallback, useEffect, useState } from "react";
import { CircularProgress, Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { connect, ConnectedProps } from "react-redux";
import { compose } from "redux";

import { Default } from "../../layouts";
import { CustomersTable } from "../../components";
import { RootState, selectProfile } from "../../store";
import { auth, firestore } from "../../firebase";
import { convertTicketSnapshot } from "../../utils/utils";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
  },
  wrapper: {
    marginTop: theme.spacing(14),
    textAlign: "center",
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

const Line: React.FC<PropsFromRedux> = ({ profile }) => {
  const classes = useStyles();
  const [tickets, setTickets] = useState<Array<any>>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState("");

  const handleIsLoaded = useCallback(() => {
    if (!isLoaded) {
      setIsLoaded(true);
    }
  }, [isLoaded]);

  useEffect(() => {
    return firestore
      .collection("tickets")
      .where("businessId", "==", auth.currentUser?.uid)
      .where("status", "in", ["pending", "invited"])
      .orderBy("createdAt")
      .onSnapshot(
        (querySnapshot) => {
          setTickets(querySnapshot.docs.map(convertTicketSnapshot));

          handleIsLoaded();
        },
        (err) => {
          setError(err.message);

          handleIsLoaded();
        }
      );
  }, [handleIsLoaded]);

  const renderContent = () => {
    if (error) {
      return (
        <div className={classes.wrapper}>
          <Typography align={"center"} variant={"h5"} component={"h1"}>
            An error occurred. Please try again later.
          </Typography>
        </div>
      );
    }

    if (!isLoaded) {
      return (
        <div className={classes.wrapper}>
          <CircularProgress />
        </div>
      );
    }

    return (
      <div className={classes.paper}>
        <Typography className={classes.title} variant={"h5"} component={"h1"}>
          Customers line
        </Typography>
        <CustomersTable tickets={tickets} />
      </div>
    );
  };

  return (
    <Default profile={profile}>
      <Container component={"main"} maxWidth={"md"}>
        {renderContent()}
      </Container>
    </Default>
  );
};

const mapStateToProps = (state: RootState) => ({
  profile: selectProfile(state),
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default compose(connector)(Line) as React.ElementType;
