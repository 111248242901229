import React from "react";
import { Form, Formik } from "formik";
import { Button, FormHelperText } from "@material-ui/core";
import { FormInput } from "../form-input";
import MuiPhoneNumber from "material-ui-phone-number";
import { makeStyles } from "@material-ui/core/styles";
import { connect, ConnectedProps } from "react-redux";

import { JOIN_VALIDATION_SCHEMA } from "./constants";
import {
  ActionTypes,
  joinLine,
  RootState,
  selectError,
  selectLoading,
} from "../../store";
import { useHistory, useLocation } from "react-router";
import { JoinFormValuesType } from "../../types";

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

interface Props extends PropsFromRedux {
  lineId: string;
}

const JoinForm: React.FC<Props> = ({ joinLine, error, loading, lineId }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const handleJoin = (customerData: JoinFormValuesType) => {
    joinLine({
      customerData,
      lineId,
    }).then(() => {
      history.push(`${location.pathname}/${localStorage.getItem("ticketId")}`);
    });
  };

  return (
    <Formik
      validationSchema={JOIN_VALIDATION_SCHEMA}
      initialValues={{ firstName: "", lastName: "", phone: "", partySize: 1 }}
      onSubmit={handleJoin}
    >
      <Form>
        <FormInput
          id={"firstName"}
          name={"firstName"}
          label={"First Name"}
          autoComplete={"given-name"}
          required
          margin="normal"
          variant="outlined"
          fullWidth
        />
        <FormInput
          id={"lastName"}
          name={"lastName"}
          label={"Last Name"}
          autoComplete={"family-name"}
          required
          margin="normal"
          variant="outlined"
          fullWidth
        />
        <FormInput
          id={"phone"}
          name={"phone"}
          comp={MuiPhoneNumber}
          defaultCountry={"us"}
          disableAreaCodes
          label={"Phone number"}
          margin="normal"
          variant="outlined"
          autoComplete={"phone"}
          fullWidth
          required
        />
        <FormInput
          id={"partySize"}
          name={"partySize"}
          label={"Party size"}
          margin="normal"
          type={"number"}
          variant="outlined"
          required
          fullWidth
        />
        <FormHelperText error={!!error}>{error}</FormHelperText>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={loading}
          className={classes.submit}
        >
          Join
        </Button>
      </Form>
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: selectLoading([ActionTypes.JOIN_LINE])(state),
  error: selectError([ActionTypes.JOIN_LINE])(state),
});

const connector = connect(mapStateToProps, { joinLine });

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(JoinForm);
