import { createSlice } from "@reduxjs/toolkit";

import { TicketInfoType } from "./join";

type LineSliceType = {
  tickets: TicketInfoType[];
  error: string;
};

const initialState: LineSliceType = {
  tickets: [],
  error: "",
};

export const lineSlice = createSlice({
  name: "line",
  initialState,
  reducers: {
    setTickets: (state, action) => {
      state.tickets = action.payload;
    },
  },
});

export const { setTickets } = lineSlice.actions;

export default lineSlice.reducer;
