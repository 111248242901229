import React from "react";
import {
  Avatar,
  Box,
  Button,
  Container,
  FormHelperText,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { LockOutlined } from "@material-ui/icons";
import { Form, Formik } from "formik";
import MuiPhoneNumber from "material-ui-phone-number";
import { connect, ConnectedProps } from "react-redux";
import { Link as RouterLink, Redirect } from "react-router-dom";

import { SIGNUP_VALIDATION_SCHEMA } from "./constants";
import { SignUpFormValuesType } from "./types";
import { FormInput } from "../../components";
import {
  ActionTypes,
  RootState,
  selectError,
  selectIsAuth,
  selectLoading,
  signUp,
} from "../../store";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Signup: React.FC<PropsFromRedux> = ({
  isAuth,
  loading,
  signUp,
  error,
}) => {
  const classes = useStyles();

  if (isAuth) {
    return <Redirect to={"/profile"} />;
  }

  const handleSignUp = async ({
    confirmationPassword,
    ...userData
  }: SignUpFormValuesType) => {
    signUp(userData);
  };

  return (
    <Container component={"main"} maxWidth={"xs"}>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlined />
        </Avatar>
        <Typography component={"h1"} variant={"h5"}>
          Sign Up
        </Typography>
      </div>
      <Formik
        validationSchema={SIGNUP_VALIDATION_SCHEMA}
        initialValues={{
          email: "",
          name: "",
          address: "",
          "address-2": "",
          country: "",
          city: "",
          state: "",
          zip: "",
          phone: "",
          password: "",
          confirmationPassword: "",
        }}
        onSubmit={handleSignUp}
      >
        <Form>
          <FormInput
            id={"email"}
            name={"email"}
            label={"Email Address"}
            autoComplete="email"
            required
            margin="normal"
            variant="outlined"
            fullWidth
          />
          <FormInput
            id={"phone"}
            name={"phone"}
            comp={MuiPhoneNumber}
            defaultCountry={"us"}
            disableAreaCodes
            label={"Phone number"}
            margin="normal"
            variant="outlined"
            autoComplete={"phone"}
            fullWidth
            required
          />
          <FormInput
            id={"name"}
            name={"name"}
            label={"Business name"}
            autoComplete={"name"}
            required
            margin="normal"
            variant="outlined"
            fullWidth
          />
          <FormInput
            id={"country"}
            name={"country"}
            label={"Country"}
            required
            margin="normal"
            variant="outlined"
            fullWidth
          />
          <FormInput
            id={"address"}
            name={"address"}
            label={"Address"}
            autoComplete={"address"}
            required
            margin="normal"
            variant="outlined"
            fullWidth
          />
          <FormInput
            id={"address-2"}
            name={"address-2"}
            label={"Address (line 2)"}
            margin="normal"
            variant="outlined"
            fullWidth
          />
          <Grid spacing={2} container justify={"space-between"}>
            <Grid item xs={4}>
              <FormInput
                id={"city"}
                name={"city"}
                label={"City"}
                required
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <FormInput
                id={"state"}
                name={"state"}
                label={"State"}
                required
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <FormInput
                id={"zip"}
                name={"zip"}
                label={"Zip Code"}
                required
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
          <FormInput
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormInput
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="confirmationPassword"
            label="Confirm password"
            type="password"
            id="confirmationPassword"
            autoComplete="current-password"
          />
          <FormHelperText error={!!error}>{error}</FormHelperText>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={loading}
          >
            Sign Up
          </Button>
          <Box display={"flex"} justifyContent={"center"}>
            <Link component={RouterLink} to={"/login"}>
              Have an account? Sign In
            </Link>
          </Box>
        </Form>
      </Formik>
    </Container>
  );
};

const mapsStateToProps = (state: RootState) => ({
  loading: selectLoading([ActionTypes.SIGNUP])(state),
  error: selectError([ActionTypes.SIGNUP])(state),
  isAuth: selectIsAuth(state),
});

const connector = connect(mapsStateToProps, { signUp });

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Signup);
