import { createSelector } from "reselect";
import _ from "lodash";

import {ActionTypes, RootState} from "../index";
import { ErrorSliceType } from "../reducers/error";

const errorSelector = (state: RootState) => state.error;

export const selectError = (actions: ActionTypes[]) =>
  createSelector(
    errorSelector,
    (error: ErrorSliceType) =>
      _(actions)
        .map((action) => error[action])
        .compact()
        .first() || ""
  );
