import { AnyAction } from "@reduxjs/toolkit";

export interface NotificationSliceType {
  [key: string]: string | void;
}

const initialState: NotificationSliceType = {};

const notification = (state = initialState, action: AnyAction) => {
  const matches = /(.+)\/(pending|fulfilled|rejected|reset)/.exec(action.type);

  if (!matches) {
    return state;
  }

  const [, requestName, requestType] = matches;

  return {
    ...state,
    [requestName]: requestType === "fulfilled" ? action.payload : "",
  };
};

export default notification;
