import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { connect, ConnectedProps } from "react-redux";

import { SettingsForm } from "./settings-form";
import { LineSettingsType, SettingsFormValuesType } from "../../../../types";
import {
  ActionTypes,
  RootState,
  selectError,
  selectLoading,
  updateLineSettings,
} from "../../../../store";

interface Props extends PropsFromRedux {
  lineSettings?: LineSettingsType;
}

const useStyles = makeStyles((theme) => ({
  dataTitle: {
    ...theme.typography["subtitle2"],
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Settings: React.FC<Props> = ({
  lineSettings,
  loading,
  updateLineSettings,
  error,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const classes = useStyles();

  const handleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleSave = (values: SettingsFormValuesType) => {
    updateLineSettings({ lineId: lineSettings?.id, data: values }).then(() =>
      setIsEditing(false)
    );
  };

  if (isEditing) {
    return (
      <SettingsForm
        loading={loading}
        lineSettings={lineSettings}
        error={error}
        handleEdit={handleEdit}
        handleSave={handleSave}
      />
    );
  }

  return (
    <div className={classes.paper}>
      <Card>
        <CardHeader
          title={"Settings"}
          action={
            <IconButton onClick={handleEdit} aria-label="edit">
              <EditIcon />
            </IconButton>
          }
        />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Typography
                className={classes.dataTitle}
                variant={"h6"}
                component={"h6"}
              >
                Maximum Grace Period (mins)
              </Typography>
              <span>{lineSettings?.gracePeriod}</span>
            </Grid>

            <Grid item md={6} xs={12}>
              <Typography
                className={classes.dataTitle}
                variant={"h6"}
                component={"h6"}
              >
                Default Service Time (mins)
              </Typography>
              <span>{lineSettings?.serviceTime}</span>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: selectLoading([ActionTypes.UPDATE_LINE_SETTINGS])(state),
  error: selectError([ActionTypes.UPDATE_PROFILE_INFO])(state),
});

const connector = connect(mapStateToProps, { updateLineSettings });

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Settings);
