import { createAsyncThunk } from "@reduxjs/toolkit";
import firebase from "firebase/app";

import { ActionTypes } from "../action-types";
import { firestore } from "../../firebase";
import { RootState } from "../index";
import { JoinLinkInfoType, TicketInfoType } from "../reducers/join";
import { api } from "../../api";
import {JoinFormValuesType} from "../../types";

export const getJoinLinkInfo = createAsyncThunk<JoinLinkInfoType, string>(
  ActionTypes.GET_JOIN_LINK_INFO,
  async (lineId, { rejectWithValue }) => {
    try {
      const line = await api.line.getJoinLinkInfo(lineId);

      if (!line.exists) {
        localStorage.removeItem("ticketId");

        return rejectWithValue(
          "This link is not valid. Please contact the host to get a valid link."
        );
      }

      return line.data() as JoinLinkInfoType;
    } catch (err) {
      return rejectWithValue(
        err.message ||
          "This link is not valid. Please contact the host to get a valid link."
      );
    }
  }
);

export const getTicket = createAsyncThunk<any, string>(
  ActionTypes.GET_TICKET,
  async (ticketId, { rejectWithValue }) => {
    try {
      const ticket = await firestore.doc(`tickets/${ticketId}`).get();

      if (!ticket.exists) {
        localStorage.removeItem("ticketId");

        return rejectWithValue(
          "This link is not valid. Please contact the host to get a valid link."
        );
      }
      const data = ticket.data() as TicketInfoType & {
        createdAt: firebase.firestore.Timestamp;
        invitedAt?: firebase.firestore.Timestamp;
      };

      return {
        ...data,
        createdAt: data.createdAt.seconds,
        invitedAt: data.invitedAt?.seconds,
      };
    } catch (err) {
      return rejectWithValue(
        err.message ||
          "This link is not valid. Please contact the host to get a valid link."
      );
    }
  }
);

export const updateTicketInfo = createAsyncThunk<
  JoinFormValuesType,
  { ticketId: string; ticketInfo: JoinFormValuesType }
>(
  ActionTypes.UPDATE_TICKET_INFO,
  async ({ ticketId, ticketInfo }, { rejectWithValue }) => {
    try {
      const batch = firestore.batch();

      batch.update(firestore.doc(`tickets/${ticketId}`), ticketInfo);
      batch.update(
        firestore.doc(`tickets/${ticketId}/public_ticket/${ticketId}`),
        {
          firstName: `${ticketInfo.firstName.slice(0, 1)}.`,
          lastName: `${ticketInfo.lastName.slice(0, 1)}.`,
        }
      );

      await batch.commit();

      return ticketInfo;
    } catch (err) {
      return rejectWithValue(
        err.response.data?.error ||
          "Something went wrong. Please try again later."
      );
    }
  }
);

export const joinLine = createAsyncThunk<
  undefined,
  { customerData: JoinFormValuesType; lineId: string }
>(
  ActionTypes.JOIN_LINE,
  async ({ customerData, lineId }, { rejectWithValue, getState }) => {
    const state = getState() as RootState;

    try {
      const ticketId = await api.line.createTicket({
        lineId,
        data: customerData,
        businessId: state.join.joinLinkInfo?.businessId,
        businessName: state.join.joinLinkInfo?.businessName,
      });

      localStorage.setItem("ticketId", ticketId);

      return;
    } catch (err) {
      return rejectWithValue(
        err.response.data?.error ||
          "Something went wrong. Please try again later."
      );
    }
  }
);

export const leaveLine = createAsyncThunk<undefined, string>(
  ActionTypes.LEAVE_LINE,
  async (ticketId, { rejectWithValue }) => {
    const data = {
      status: "canceled",
      canceledAt: firebase.firestore.Timestamp.now(),
    };

    try {
      await api.line.leaveLine({ ticketId, data });

      return;
    } catch (err) {
      return rejectWithValue(
        err.response.data?.error ||
          "Something went wrong. Please try again later."
      );
    }
  }
);

export const takeSeat = createAsyncThunk<undefined, string>(
  ActionTypes.TAKE_SEAT,
  async (ticketId, { rejectWithValue }) => {
    const data = {
      status: "confirmed",
      confirmedAt: firebase.firestore.Timestamp.now(),
    };

    try {
      const batch = firestore.batch();

      batch.set(firestore.doc(`tickets/${ticketId}`), data, { merge: true });
      batch.set(
        firestore.doc(`tickets/${ticketId}/public_ticket/${ticketId}`),
        data,
        { merge: true }
      );

      await batch.commit();

      return;
    } catch (err) {
      return rejectWithValue(
        err.response.data?.error ||
          "Something went wrong. Please try again later."
      );
    }
  }
);
