import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { TicketRow } from "./ticket-row";
import { TicketInfoType } from "../../../store/reducers/join";

interface Props {
  tickets: TicketInfoType[];
}

const TicketsHistoryTable: React.FC<Props> = ({ tickets }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Name</TableCell>
            <TableCell align={"right"}>Line status</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {tickets.map((ticket) => (
            <TicketRow key={ticket.id} {...ticket} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TicketsHistoryTable;
