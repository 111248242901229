import * as Yup from "yup";
import "yup-phone";

export const PROFILE_VALIDATION_SCHEMA = Yup.object({
  name: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  zip: Yup.string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(5, "Must be exactly 5 or 6 digits")
    .max(6, "Must be exactly 5 or 6 digits")
    .required("Required"),
  phone: Yup.string().phone().required("Required"),
});
