import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { connect, ConnectedProps } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";

import {
  ActionTypes,
  leaveLine,
  RootState,
  selectLoading,
  takeSeat,
  updateTicketInfo,
} from "../../../store";
import { WaitingCardForm } from "./waiting-card-form";
import { Actions } from "./actions";
import { Header } from "./header";
import { setJoined, TicketInfoType } from "../../../store/reducers/join";
import { getTicketWaitingTime } from "../line-table/utils";

const useStyles = makeStyles((theme) => ({
  listItemText: {
    flex: "1 1 0",

    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    "&:last-child": {
      color: theme.palette.grey["500"],
    },
  },
}));

interface Props extends PropsFromRedux {
  ticket: TicketInfoType;
}

const WaitingCard: React.FC<Props> = ({
  ticket,
  leaveLine,
  loading,
  takeSeat,
  setJoined,
}) => {
  const classes = useStyles();

  const [isEditing, setIsEditing] = useState(false);
  const { ticketId, lineId } =
    useParams<{ ticketId: string; lineId: string }>();
  const [, setTime] = useState<number>();

  const history = useHistory();

  useEffect(() => {
    if (!loading) {
      setIsEditing(false);
    }
  }, [loading]);

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1000 * 20);

    return () => {
      clearInterval(interval);
    };
  }, [ticket.createdAt]);

  const handleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleLeave = () => {
    localStorage.removeItem("ticketId");
    leaveLine(ticketId);
  };

  const handleConfirm = () => {
    localStorage.removeItem("ticketId");
    takeSeat(ticketId);
  };

  const handleJoin = () => {
    localStorage.removeItem("ticketId");
    setJoined(false);
    history.push(`/join/${lineId}`);
  };

  if (isEditing) {
    return (
      <WaitingCardForm
        ticketInfo={ticket}
        ticketId={ticketId}
        handleEdit={handleEdit}
      />
    );
  }

  const renderStatus = (status: string) => {
    if (status === "invited") return "Invited";
    if (status === "canceled") return "Canceled";
    if (status === "confirmed") return "Confirmed";
    if (status === "pending") return "In Line";

    return "Non-appearance";
  };

  return (
    <Card variant="outlined">
      <Header status={ticket?.status} handleEdit={handleEdit} />
      <CardContent>
        <List>
          <ListItem disableGutters>
            <ListItemText className={classes.listItemText}>First Name</ListItemText>
            <ListItemText className={classes.listItemText}>
              {ticket?.firstName}
            </ListItemText>
          </ListItem>
          <ListItem disableGutters>
            <ListItemText className={classes.listItemText}>Last Name</ListItemText>
            <ListItemText className={classes.listItemText}>
              {ticket?.lastName}
            </ListItemText>
          </ListItem>
          <ListItem disableGutters>
            <ListItemText className={classes.listItemText}>
              Phone Number
            </ListItemText>
            <ListItemText className={classes.listItemText}>
              {ticket?.phone}
            </ListItemText>
          </ListItem>
          <ListItem disableGutters>
            <ListItemText className={classes.listItemText}>
              Party Size
            </ListItemText>
            <ListItemText className={classes.listItemText}>
              {ticket?.partySize}
            </ListItemText>
          </ListItem>
          <ListItem disableGutters>
            <ListItemText className={classes.listItemText}>Status</ListItemText>
            <ListItemText className={classes.listItemText}>
              {renderStatus(ticket?.status)}
            </ListItemText>
          </ListItem>
          <ListItem disableGutters>
            <ListItemText className={classes.listItemText}>
              Time in Line
            </ListItemText>
            <ListItemText className={classes.listItemText}>
              {getTicketWaitingTime(ticket)} mins
            </ListItemText>
          </ListItem>
        </List>
      </CardContent>
      <Actions
        status={ticket.status}
        handleJoin={handleJoin}
        handleLeave={handleLeave}
        handleConfirm={handleConfirm}
        loading={loading}
      />
    </Card>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: selectLoading([
    ActionTypes.UPDATE_TICKET_INFO,
    ActionTypes.LEAVE_LINE,
    ActionTypes.TAKE_SEAT,
  ])(state),
});

const connector = connect(mapStateToProps, {
  updateTicketInfo,
  leaveLine,
  takeSeat,
  setJoined,
});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(WaitingCard);
