import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";

import { Settings } from "./settings";
import { QrCode } from "../../../components";
import { auth } from "../../../firebase";
import {
  ActionTypes,
  getLineSettings,
  RootState,
  selectLineSettings,
  selectLoading,
} from "../../../store";
import { CircularProgress, Typography } from "@material-ui/core";

const LineInfo: React.FC<PropsFromRedux> = ({
  loading,
  error,
  lineSettings,
  getLineSettings,
}) => {
  useEffect(() => {
    getLineSettings(auth.currentUser?.uid);
  }, [getLineSettings]);

  if (error) {
    return <Typography variant={"h5"}>{error}</Typography>;
  }

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Settings lineSettings={lineSettings} />
      <QrCode value={`${window.location.origin}/join/${lineSettings?.id}`} />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  lineSettings: selectLineSettings(state),
  loading: selectLoading([ActionTypes.GET_LINE_SETTINGS])(state),
  error: selectLoading([ActionTypes.GET_LINE_SETTINGS])(state),
});

const connector = connect(mapStateToProps, { getLineSettings });

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(LineInfo);
