import { Action } from "redux";

export interface LoadingSliceType {
  [key: string]: boolean;
}

const initialState: LoadingSliceType = {};

const loading = (state = initialState, action: Action) => {
  const matches = /(.+)\/(pending|fulfilled|rejected)/.exec(action.type);

  if (!matches) {
    return state;
  }

  const [, requestName, requestType] = matches;

  return {
    ...state,
    [requestName]: requestType === "pending",
  };
};

export default loading;
