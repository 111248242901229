import React from "react";
import { Button, CardActions } from "@material-ui/core";

interface Props {
  status: string;
  handleJoin: () => void;
  handleLeave: () => void;
  handleConfirm: () => void;
  loading: boolean;
}

const Actions: React.FC<Props> = ({
  loading,
  status,
  handleJoin,
  handleLeave,
  handleConfirm,
}) => {
  const renderActions = () => {
    switch (status) {
      case "canceled":
      case "confirmed":
      case "non-appearance":
        return (
          <Button onClick={handleJoin} color={"primary"}>
            Join the line again
          </Button>
        );
      case "pending":
        return (
          <Button disabled={loading} onClick={handleLeave} color={"primary"}>
            Leave the line
          </Button>
        );
      case "invited":
        return (
          <Button
            disabled={loading}
            onClick={handleConfirm}
            color={"primary"}
          >
            Take a seat
          </Button>
        );
    }
  };

  return <CardActions>{renderActions()}</CardActions>;
};

export default Actions;
