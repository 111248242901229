import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { CssBaseline } from "@material-ui/core";

import {
  Join,
  Line,
  Ticket,
  Login,
  Profile,
  Signup,
  ForgotPassword, History,
} from "./pages";
import { PrivateRoute } from "./components";
import AuthWrapper from "./hocs/auth-wrapper";

function App() {
  return (
    <Router>
      <AuthWrapper>
        <CssBaseline />
        <Switch>
          <Route path="/signup">
            <Signup />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/forgot-password">
            <ForgotPassword />
          </Route>
          <PrivateRoute path="/profile">
            <Profile />
          </PrivateRoute>
          <PrivateRoute path="/line">
            <Line />
          </PrivateRoute>
          <PrivateRoute path="/history">
            <History />
          </PrivateRoute>
          <Route exact path="/join/:lineId" component={Join} />
          <Route
            exact
            path="/join/:lineId/:ticketId"
            component={Ticket}
          />
          <Route path="/">
            <Redirect to={"/profile"} />
          </Route>
        </Switch>
      </AuthWrapper>
    </Router>
  );
}

export default App;
