import React from "react";
import { Form, Formik } from "formik";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormHelperText,
  Grid,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { PROFILE_VALIDATION_SCHEMA } from "./constants";
import { FormInput } from "../../../../components/form-input";
import MuiPhoneNumber from "material-ui-phone-number";
import { makeStyles } from "@material-ui/core/styles";
import { ProfileFormValuesType } from "./types";
import { BusinessProfileType } from "../../../../types";

interface Props {
  profile?: BusinessProfileType;
  handleEdit: () => void;
  handleSave: (values: ProfileFormValuesType) => void;
  loading: boolean;
  error: string;
}

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
  },
}));

const ProfileForm: React.FC<Props> = ({
  profile,
  handleEdit,
  loading,
  error,
  handleSave,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.paper}>
      <Formik
        validationSchema={PROFILE_VALIDATION_SCHEMA}
        initialValues={{
          name: profile?.name || "",
          phone: profile?.phone || "",
          country: profile?.address.country || "",
          address: profile?.address.address || "",
          "address-2": profile?.address["address-2"] || "",
          state: profile?.address.state || "",
          city: profile?.address.city || "",
          zip: profile?.address.zip || "",
        }}
        onSubmit={handleSave}
      >
        <Form>
          <Card>
            <CardHeader
              title={"Profile Info"}
              action={
                <IconButton onClick={handleEdit} aria-label="edit">
                  <CloseIcon />
                </IconButton>
              }
            />
            <CardContent>
              <Grid container direction={"column"} spacing={1} md={6} xs={12}>
                <Grid item>
                  <FormInput
                    id={"name"}
                    name={"name"}
                    label={"Business name"}
                    autoComplete={"name"}
                    required
                    margin="dense"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <FormInput
                    id={"phone"}
                    name={"phone"}
                    comp={MuiPhoneNumber}
                    defaultCountry={"us"}
                    disableAreaCodes
                    label={"Phone number"}
                    margin="dense"
                    variant="outlined"
                    autoComplete={"phone"}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item>
                  <FormInput
                    id={"country"}
                    name={"country"}
                    label={"Country"}
                    autoComplete={"address"}
                    required
                    margin="dense"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <FormInput
                    id={"address"}
                    name={"address"}
                    label={"Address"}
                    autoComplete={"address"}
                    required
                    margin="dense"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <FormInput
                    id={"address-2"}
                    name={"address-2"}
                    label={"Address (line 2)"}
                    autoComplete={"address"}
                    margin="dense"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <FormInput
                    id={"state"}
                    name={"state"}
                    label={"State"}
                    autoComplete={"address"}
                    required
                    margin="dense"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <FormInput
                    id={"city"}
                    name={"city"}
                    label={"City"}
                    required
                    margin="dense"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <FormInput
                    id={"zip"}
                    name={"zip"}
                    label={"Zip Code"}
                    autoComplete={"address"}
                    required
                    margin="dense"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <FormHelperText error={!!error}>{error}</FormHelperText>
            </CardContent>
            <CardActions>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                className={classes.submit}
                disabled={loading}
              >
                Save details
              </Button>
            </CardActions>
          </Card>
        </Form>
      </Formik>
    </div>
  );
};

export default ProfileForm;
