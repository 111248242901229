import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

export const app = firebase.initializeApp({
  apiKey: "AIzaSyC8G9Mn0hh7JzxU2TbC2_o-hOJk4nIkx-Q",
  authDomain: "foxline-d0860.firebaseapp.com",
  projectId: "foxline-d0860",
  storageBucket: "foxline-d0860.appspot.com",
  messagingSenderId: "422713844647",
  appId: "1:422713844647:web:962c0972871913c64acc82",
  measurementId: "G-R2H1EWTYXM",
});

export const auth = app.auth();
export const firestore = app.firestore();

if (process.env.NODE_ENV === "development") {
  app.firestore().useEmulator("localhost", 8080);
  app.auth().useEmulator("http://localhost:9099");
}
