import React, { useCallback, useEffect, useState } from "react";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { CenteredLoader, Error, WaitingCard } from "../../components";
import { LineTable } from "./line-table";
import { RouteComponentProps } from "react-router";
import { firestore } from "../../firebase";
import { convertTicketSnapshot } from "../../utils/utils";
import { TicketInfoType } from "../../store/reducers/join";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)",
  },
  paper: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
}));

type RouteProps = RouteComponentProps<{
  lineId: string;
  ticketId: string;
}>;

const Ticket: React.FC<RouteProps> = ({
  match: {
    params: { lineId, ticketId },
  },
}) => {
  const classes = useStyles();

  const [ticket, setTicket] = useState<TicketInfoType>();
  const [error, setError] = useState<string>();

  const [isLoaded, setIsLoaded] = useState(false);

  const handleIsLoaded = useCallback(() => {
    if (!isLoaded) {
      setIsLoaded(true);
    }
  }, [isLoaded]);

  useEffect(() => {
    firestore.doc(`tickets/${ticketId}`).onSnapshot(
      (querySnapshot) => {
        if (!querySnapshot.exists) {
          setError(
            "This link is not valid. Please contact the host to get a valid link."
          );
        } else {
          setTicket(convertTicketSnapshot(querySnapshot as any));
        }

        handleIsLoaded();
      },
      () => {
        setError("An error occurred. Please, try again.");
        handleIsLoaded();
      }
    );
  }, [ticketId, handleIsLoaded]);

  if (!isLoaded) {
    return <CenteredLoader />;
  }

  if (error) {
    localStorage.removeItem("ticketId");

    return <Error error={error} />;
  }

  return (
    <Container component={"main"} maxWidth={"md"}>
      <div className={classes.paper}>
        {ticket?.status === "pending" && (
          <LineTable ticketId={ticketId} lineId={lineId} />
        )}
        {ticket && <WaitingCard ticket={ticket} />}
      </div>
    </Container>
  );
};

export default Ticket;
