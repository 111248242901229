import { createAsyncThunk } from "@reduxjs/toolkit";

import { LoginFormValuesType } from "../../pages/login/types";
import { SignUpUserDataType } from "../../pages/signup/types";
import { ForgotPasswordFormValuesType } from "../../pages/forgot-password/types";
import { ActionTypes } from "../action-types";
import { auth } from "../../firebase";
import { api } from "../../api";

export const initAuth = createAsyncThunk<any, string | undefined>(
  ActionTypes.INIT_AUTH,
  async (uid, { rejectWithValue }) => {
    try {
      return auth.currentUser?.uid
        ? await api.profile.getProfile(auth.currentUser?.uid)
        : undefined;
    } catch (err) {
      if (
        err.code === "auth/user-not-found" ||
        err.code === "auth/wrong-password"
      ) {
        return rejectWithValue("The email address or password is incorrect.");
      }

      return rejectWithValue("Something went wrong. Please try again later.");
    }
  }
);

export const login = createAsyncThunk<any, LoginFormValuesType>(
  ActionTypes.LOGIN,
  async (userData: LoginFormValuesType, { rejectWithValue }) => {
    try {
      await auth.signInWithEmailAndPassword(userData.email, userData.password);

      return await api.profile.getProfile(auth.currentUser?.uid);
    } catch (err) {
      if (
        err.code === "auth/user-not-found" ||
        err.code === "auth/wrong-password"
      ) {
        return rejectWithValue("The email address or password is incorrect.");
      }

      return rejectWithValue("Something went wrong. Please try again later.");
    }
  }
);

export const logout = createAsyncThunk<undefined, void>(
  "logout",
  async (_, { rejectWithValue }) => {
    try {
      await auth.signOut();
    } catch (err) {
      return rejectWithValue("Something went wrong. Please try again later.");
    }
  }
);

export const resetPassword = createAsyncThunk<
  string,
  ForgotPasswordFormValuesType
>(
  ActionTypes.RESET_PASSWORD,
  async (forgotPasswordData, { rejectWithValue }) => {
    try {
      await auth.sendPasswordResetEmail(forgotPasswordData.email);

      return "Reset link has been sent to your email.";
    } catch (err) {
      if (err.code === "auth/user-not-found") {
        return rejectWithValue("Provided email address is not registered.");
      }

      return rejectWithValue("Something went wrong. Please try again later.");
    }
  }
);

export const signUp = createAsyncThunk<any, SignUpUserDataType>(
  ActionTypes.SIGNUP,
  async (data, { rejectWithValue }) => {
    try {
      const { user } = await auth.createUserWithEmailAndPassword(
        data.email,
        data.password
      );

      await api.profile.createProfile(user?.uid, data);

      return await api.profile.getProfile(user?.uid);
    } catch (err) {
      if (err.code === "auth/email-already-in-use") {
        return rejectWithValue("This email address is already in use.");
      }

      return rejectWithValue("Something went wrong. Please try again later.");
    }
  }
);
