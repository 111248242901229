import React from "react";
import { Form, Formik } from "formik";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import MuiPhoneNumber from "material-ui-phone-number";
import SaveIcon from "@material-ui/icons/Save";
import { makeStyles } from "@material-ui/core/styles";
import { connect, ConnectedProps } from "react-redux";

import { FormInput } from "../../../../components/form-input";
import { updateTicketInfo } from "../../../../store";
import { TicketInfoType } from "../../../../store/reducers/join";
import { JOIN_VALIDATION_SCHEMA } from "../../../../components/join-form/constants";
import { JoinFormValuesType } from "../../../../types";

const useStyles = makeStyles((theme) => ({
  listItem: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "start",
    },
  },
  listItemText: {
    flex: "1 1 0",

    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    "&:last-child": {
      color: theme.palette.grey["500"],
    },
  },
}));

interface Props extends PropsFromRedux {
  ticketInfo: TicketInfoType;
  handleEdit: () => void;
  ticketId: string;
}

const WaitingCardForm: React.FC<Props> = ({
  handleEdit,
  updateTicketInfo,
  ticketInfo,
  ticketId,
}) => {
  const classes = useStyles();

  const edit = (values: JoinFormValuesType) => {
    updateTicketInfo({
      ticketId,
      ticketInfo: values,
    });
  };

  return (
    <Card variant="outlined">
      <Formik
        onSubmit={edit}
        validationSchema={JOIN_VALIDATION_SCHEMA}
        initialValues={{
          firstName: ticketInfo?.firstName,
          lastName: ticketInfo?.lastName,
          phone: ticketInfo?.phone,
          partySize: ticketInfo?.partySize,
        }}
      >
        <Form>
          <CardHeader
            title={"Your Contact Details"}
            subheader={"Make sure it's correct. We'll you send a notification."}
            action={
              <IconButton onClick={handleEdit} aria-label="edit">
                <CloseIcon />
              </IconButton>
            }
          />
          <CardContent>
            <List>
              <ListItem className={classes.listItem} disableGutters>
                <ListItemText className={classes.listItemText}>
                  First Name
                </ListItemText>
                <ListItemText className={classes.listItemText}>
                  <FormInput
                    id={"firstName"}
                    name={"firstName"}
                    label={"First Name"}
                    autoComplete={"given-name"}
                    required
                    fullWidth
                  />
                </ListItemText>
              </ListItem>
              <ListItem className={classes.listItem} disableGutters>
                <ListItemText className={classes.listItemText}>
                  Name
                </ListItemText>
                <ListItemText className={classes.listItemText}>
                  <FormInput
                    id={"lastName"}
                    name={"lastName"}
                    label={"Last Name"}
                    autoComplete={"family-name"}
                    required
                    fullWidth
                  />
                </ListItemText>
              </ListItem>
              <ListItem className={classes.listItem} disableGutters>
                <ListItemText className={classes.listItemText}>
                  Phone Number
                </ListItemText>
                <ListItemText className={classes.listItemText}>
                  <FormInput
                    id={"phone"}
                    name={"phone"}
                    comp={MuiPhoneNumber}
                    defaultCountry={"us"}
                    disableAreaCodes
                    label={"Phone number"}
                    autoComplete={"phone"}
                    fullWidth
                    required
                  />
                </ListItemText>
              </ListItem>
              <ListItem className={classes.listItem} disableGutters>
                <ListItemText className={classes.listItemText}>
                  Party Size
                </ListItemText>
                <ListItemText className={classes.listItemText}>
                  <FormInput
                    id={"partySize"}
                    name={"partySize"}
                    label={"Party size"}
                    required
                    fullWidth
                  />
                </ListItemText>
              </ListItem>
            </List>
          </CardContent>
          <CardActions>
            <Button type={"submit"} color={"primary"} startIcon={<SaveIcon />}>
              Save
            </Button>
          </CardActions>
        </Form>
      </Formik>
    </Card>
  );
};

const connector = connect(null, {
  updateTicketInfo,
});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(WaitingCardForm);
