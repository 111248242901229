import React from "react";
import {
  Divider,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import HistoryIcon from "@material-ui/icons/History";
import { Link } from "react-router-dom";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import PeopleIcon from "@material-ui/icons/People";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { makeStyles } from "@material-ui/core/styles";
import { connect, ConnectedProps, useDispatch } from "react-redux";

import { logout } from "../../store";

interface Props extends PropsFromRedux {
  name: string;
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
}));

const Panel: React.FC<Props> = ({ name, mobileOpen, handleDrawerToggle }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <Typography variant="h6" align={"center"}>
        {name}
      </Typography>
      <List>
        <ListItem button key="Account" component={Link} to={"/profile"}>
          <ListItemIcon>
            <AccountBoxIcon />
          </ListItemIcon>
          <ListItemText primary="Account" />
        </ListItem>

        <ListItem button key="Line" component={Link} to={"/line"}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Line" />
        </ListItem>

        <ListItem button key="History" component={Link} to={"/history"}>
          <ListItemIcon>
            <HistoryIcon />
          </ListItemIcon>
          <ListItemText primary="History" />
        </ListItem>

        <ListItem onClick={handleLogout} button key="Logout">
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <nav className={classes.drawer} aria-label="menu">
      <Hidden smUp>
        <Drawer
          variant="temporary"
          classes={{
            paper: classes.drawerPaper,
          }}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>

      <Hidden xsDown>
        <Drawer
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          open
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
};

const connector = connect(null, { logout });

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Panel);
