import firebase from "firebase/app";

import { firestore } from "../firebase";
import { JoinFormValuesType } from "../types";

export const getJoinLinkInfo = (lineId: string) =>
  firestore.doc(`lines/${lineId}`).get();

export const createTicket = async ({
  data,
  lineId,
  businessId,
  businessName,
}: {
  data: JoinFormValuesType;
  lineId: string;
  businessId?: string;
  businessName?: string;
}) => {
  const batch = firestore.batch();

  const newTicket = firestore.collection(`tickets`).doc();
  const createdAt = firebase.firestore.Timestamp.now();

  batch.set(newTicket, {
    ...data,
    status: "pending",
    createdAt,
    businessName,
    lineId,
    businessId,
  });

  batch.set(
    firestore.doc(`tickets/${newTicket.id}/public_ticket/${newTicket.id}`),
    {
      firstName: `${data.firstName.slice(0, 1)}.`,
      lastName: `${data.lastName.slice(0, 1)}.`,
      status: "pending",
      createdAt,
      lineId,
      businessId,
    }
  );

  await batch.commit();

  return newTicket.id;
};

export const leaveLine = async ({
  ticketId,
  data,
}: {
  ticketId: string;
  data: { status: string; canceledAt: firebase.firestore.Timestamp };
}) => {
  const batch = firestore.batch();

  batch.set(firestore.doc(`tickets/${ticketId}`), data, { merge: true });
  batch.set(
    firestore.doc(`tickets/${ticketId}/public_ticket/${ticketId}`),
    data,
    { merge: true }
  );

  await batch.commit();
};
