import React from "react";
import { Form, Formik } from "formik";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormHelperText,
  Grid,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { LineSettingsType, SettingsFormValuesType } from "../../../../../types";
import { SETTINGS_VALIDATION_SCHEMA } from "./constants";
import { FormInput } from "../../../../../components";

interface Props {
  lineSettings?: LineSettingsType;
  handleSave: (data: SettingsFormValuesType) => void;
  handleEdit: () => void;
  loading: boolean;
  error: string;
}

const useStyles = makeStyles((theme) => ({
  dataTitle: {
    ...theme.typography["subtitle2"],
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SettingsForm: React.FC<Props> = ({
  lineSettings,
  handleSave,
  handleEdit,
  loading,
  error,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.paper}>
      <Formik
        validationSchema={SETTINGS_VALIDATION_SCHEMA}
        initialValues={{
          gracePeriod: lineSettings?.gracePeriod || 1,
          serviceTime: lineSettings?.serviceTime || 1,
        }}
        onSubmit={handleSave}
      >
        <Form>
          <Card>
            <CardHeader
              title={"Settings"}
              action={
                <IconButton onClick={handleEdit} aria-label="edit">
                  <CloseIcon />
                </IconButton>
              }
            />
            <CardContent>
              <Grid container direction={"column"} spacing={3} md={6} xs={12}>
                <Grid item>
                  <FormInput
                    id={"gracePeriod"}
                    name={"gracePeriod"}
                    label={"Maximum Grace Period (mins)"}
                    margin="dense"
                    type="number"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <FormInput
                    id={"serviceTime"}
                    name={"serviceTime"}
                    label={"Default Service Time"}
                    margin="dense"
                    type="number"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <FormHelperText error={!!error}>{error}</FormHelperText>
            </CardContent>
            <CardActions>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                className={classes.submit}
                disabled={loading}
              >
                Save
              </Button>
            </CardActions>
          </Card>
        </Form>
      </Formik>
    </div>
  );
};

export default SettingsForm;
