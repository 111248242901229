import { createAsyncThunk } from "@reduxjs/toolkit";

import { CredentialsType } from "../../components/credentials-form/types";
import { ActionTypes } from "../action-types";
import { auth, firestore } from "../../firebase";
import { ProfileFormValuesType } from "../../pages/profile/profile-info/profile-form/types";
import { BusinessProfileType, SettingsFormValuesType } from "../../types";
import { api } from "../../api";

export const getLineSettings = createAsyncThunk<any, string | undefined>(
  ActionTypes.GET_LINE_SETTINGS,
  async (businessId, { rejectWithValue }) => {
    try {
      return await api.profile.getLineSettings(businessId);
    } catch (err) {
      return rejectWithValue("Something went wrong. Please try again later.");
    }
  }
);

export const updateLineSettings = createAsyncThunk<
  SettingsFormValuesType,
  { data: SettingsFormValuesType; lineId?: string }
>(
  ActionTypes.UPDATE_LINE_SETTINGS,
  async ({ data, lineId }, { rejectWithValue }) => {
    try {
      await api.profile.updateLineSettings(data, lineId);

      return data;
    } catch (err) {
      return rejectWithValue(
        err.response.data?.error ||
          "Something went wrong. Please try again later."
      );
    }
  }
);

export const updateProfileInfo = createAsyncThunk<
  Partial<BusinessProfileType>,
  ProfileFormValuesType
>(ActionTypes.UPDATE_PROFILE_INFO, async (values, { rejectWithValue }) => {
  const data = {
    address: {
      address: values.address,
      "address-2": values["address-2"],
      city: values.city,
      country: values.country,
      state: values.state,
      zip: values.zip,
    },
    name: values.name,
    phone: values.phone,
  };

  try {
    await firestore.doc(`businesses/${auth.currentUser?.uid}`).update(data);

    return data;
  } catch (err) {
    return rejectWithValue(
      err.response.data?.error ||
        "Something went wrong. Please try again later."
    );
  }
});

export const updateCredentials = createAsyncThunk<
  Partial<Pick<BusinessProfileType, "email">>,
  CredentialsType
>(
  ActionTypes.UPDATE_PROFILE_CREDENTIALS,
  async ({ email, password }, { rejectWithValue }) => {
    try {
      if (password) {
        await auth.currentUser?.updatePassword(password);
      }

      if (email !== auth.currentUser?.email) {
        await auth.currentUser?.updateEmail(email);
        await firestore
          .doc(`businesses/${auth.currentUser?.uid}`)
          .set({ email }, { merge: true });

        return { email };
      }

      return {};
    } catch (err) {
      return rejectWithValue(
        err.response.data?.error ||
          "Something went wrong. Please try again later."
      );
    }
  }
);
