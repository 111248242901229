import React, { useState } from "react";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InsertInvitationIcon from "@material-ui/icons/InsertInvitation";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { connect, ConnectedProps } from "react-redux";

import { TicketInfoType } from "../../../store/reducers/join";
import { ActionTypes, invite, RootState, selectLoading } from "../../../store";
import { secondsToMinutes } from "../../../utils/utils";

interface Props extends TicketInfoType, PropsFromRedux {
  numberInLine: number;
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  invite: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    justifyContent: "flex-end",
  },
}));

const Row: React.FC<Props> = ({
  id,
  status,
  firstName,
  lastName,
  phone,
  numberInLine,
  createdAt,
  partySize,
  invite,
  loading,
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleInvite = () => {
    invite(id);
  };

  const renderWaitingTime = () =>
    `${secondsToMinutes(Date.now() / 1000 - createdAt)} mins`;

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {firstName}
        </TableCell>
        <TableCell align={"right"}>{numberInLine}</TableCell>
        <TableCell align={"right"}>
          {status !== "pending" ? (
            <p className={classes.invite}>
              Invitation sent
              <ScheduleIcon />
            </p>
          ) : (
            <Button
              variant={"contained"}
              color={"primary"}
              endIcon={<InsertInvitationIcon />}
              onClick={handleInvite}
              disabled={loading}
            >
              Invite
            </Button>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Customer Info
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell align="right">Phone Number</TableCell>
                    <TableCell align="right">Party Size</TableCell>
                    <TableCell align="right">Time in Line</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{firstName}</TableCell>
                    <TableCell>{lastName}</TableCell>
                    <TableCell align="right">{phone}</TableCell>
                    <TableCell align="right">{partySize}</TableCell>
                    <TableCell align="right">{renderWaitingTime()}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: selectLoading([ActionTypes.INVITE])(state),
});

const connector = connect(mapStateToProps, { invite });

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Row);
