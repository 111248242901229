export enum ActionTypes {
  LOGIN = "LOGIN",
  SIGNUP = "SIGNUP",
  INIT_AUTH = "INIT_AUTH",
  RESET_PASSWORD = "RESET_PASSWORD",
  INVITE = "INVITE",
  UPDATE_PROFILE_CREDENTIALS = "UPDATE_PROFILE_CREDENTIALS",
  UPDATE_PROFILE_INFO = "UPDATE_PROFILE_INFO",
  GET_LINE_SETTINGS = "GET_LINE_SETTINGS",
  UPDATE_LINE_SETTINGS = "UPDATE_LINE_SETTINGS",
  JOIN_LINE = "JOIN_LINE",
  LEAVE_LINE = "LEAVE_LINE",
  TAKE_SEAT = "TAKE_SEAT",
  UPDATE_TICKET_INFO = "UPDATE_TICKET_INFO",
  GET_JOIN_LINK_INFO = "GET_JOIN_LINK_INFO",
  GET_TICKET = "GET_TICKET"
}
