import _ from "lodash";

import { firestore } from "../firebase";
import { SettingsFormValuesType } from "../types";
import { SignUpUserDataType } from "../pages/signup/types";

export const createProfile = async (
  uid: string | undefined,
  data: SignUpUserDataType
) => {
  const batch = firestore.batch();

  const profileRef = firestore.doc(`businesses/${uid}`);
  batch.set(profileRef, {
    email: data.email,
    name: data.name,
    phone: data.phone,
    address: {
      address: data.address,
      "address-2": data["address-2"],
      city: data.city,
      country: data.country,
      state: data.state,
      zip: data.zip,
    },
  });

  const lineRef = firestore.collection(`lines`);
  batch.set(lineRef.doc(), {
    businessName: data.name,
    gracePeriod: 5,
    serviceTime: 10,
    businessId: profileRef.id,
  });

  await batch.commit();
};

export const getLineSettings = async (businessId: string | undefined) => {
  const doc = _.head(
    (
      await firestore
        .collection(`lines`)
        .where("businessId", "==", businessId)
        .get()
    ).docs
  );

  return {
    id: doc?.id,
    ...doc?.data(),
  };
};

export const updateLineSettings = async (
  data: SettingsFormValuesType,
  lineId?: string
) => firestore.doc(`lines/${lineId}`).update(data);

export const getProfile = async (uid: string | undefined) =>
  (await firestore.doc(`businesses/${uid}`).get()).data();
