import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { Row } from "./row";
import { TicketInfoType } from "../../store/reducers/join";

interface Props {
  tickets: TicketInfoType[];
}

const CustomersTable: React.FC<Props> = ({ tickets }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Name</TableCell>
            <TableCell align={"right"}>Number in Line</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {tickets.map((row, i) => (
            <Row key={row.id} {...row} numberInLine={i + 1} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomersTable;
