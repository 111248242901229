import { combineReducers, configureStore, Middleware } from "@reduxjs/toolkit";
import logger from "redux-logger";

import {
  auth,
  join,
  line,
  loading,
  error,
  notification,
} from "./reducers";

let middlewares: [Middleware] | [] = [];

if (process.env.NODE_ENV === "development") {
  middlewares = [logger];
}

const rootReducer = combineReducers({
  auth,
  join,
  line,
  loading,
  error,
  notification,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(...middlewares),
  devTools: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof rootReducer>;

export * from "./actions";
export * from "./selectors";

export { ActionTypes } from "./action-types";
