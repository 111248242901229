import { createSelector } from "reselect";

import { ActionTypes, RootState } from "../index";
import { LoadingSliceType } from "../reducers/loading";

const loadingSlice = (state: RootState) => state.loading;

export const selectLoading = (actions: ActionTypes[]) =>
  createSelector(loadingSlice, (loading: LoadingSliceType) =>
    actions.some((action) => loading[action])
  );
