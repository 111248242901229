import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Header, Panel } from "../../components";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
  },
  loaderWrapper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)",
  },
}));

interface Props {
  profile: any;
}

const Default: React.FC<Props> = ({ profile, children }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const classes = useStyles();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div>
      <Header handleDrawerToggle={handleDrawerToggle} />
      <div className={classes.container}>
        <Panel
          name={profile.name}
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
        />
        {children}
      </div>
    </div>
  );
};

export default Default;
