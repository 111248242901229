import React from "react";
import { Container } from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";

import { CredentialsForm } from "../../components";
import { Default } from "../../layouts/default";
import { RootState, selectProfile } from "../../store";
import { LineInfo } from "./line-info";
import { ProfileInfo } from "./profile-info";

const Profile: React.FC<PropsFromRedux> = ({ profile }) => (
  <Default profile={profile}>
    <Container component={"main"} maxWidth={"md"}>
      <ProfileInfo profile={profile} />
      <CredentialsForm profile={profile} />
      <LineInfo />
    </Container>
  </Default>
);

const mapStateToProps = (state: RootState) => ({
  profile: selectProfile(state),
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Profile);
