import * as profileAPIs from "./profile";
import * as lineAPIs from "./line";

export const api = {
  line: {
    ...lineAPIs,
  },
  profile: {
    ...profileAPIs,
  },
};
