import * as Yup from "yup";
import "yup-phone";

export const SIGNUP_VALIDATION_SCHEMA = Yup.object({
  email: Yup.string().email("Invalid email address").required("Required"),
  name: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  zip: Yup.string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(5, "Must be exactly 5 or 6 digits")
    .max(6, "Must be exactly 5 or 6 digits")
    .required("Required"),
  phone: Yup.string().phone().required("Required"),
  password: Yup.string()
    .min(6, "Must be 6 at least 6 characters")
    .max(20, "Must be 20 characters or less")
    .required("Required"),
  confirmationPassword: Yup.string().when("password", {
    is: (val: string) => !!val,
    then: Yup.string().oneOf([Yup.ref("password")], "Passwords should match"),
  }),
});
