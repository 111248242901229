import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  Tooltip,
  Typography,
} from "@material-ui/core";
import QRCode from "qrcode.react";
import LinkIcon from "@material-ui/icons/Link";
import { makeStyles } from "@material-ui/core/styles";
import {
  Document,
  Image,
  Page,
  pdf,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { saveAs } from "file-saver";

interface Props {
  value: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(6),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const QrCode: React.FC<Props> = ({ value }) => {
  const [message, setMessage] = useState("");
  const classes = useStyles();

  const generatePDF = async () => {
    const qrCodeCanvas = document.querySelector("canvas");
    const qrCodeURI = qrCodeCanvas?.toDataURL("image/jpg", 0.3);

    const blob = await pdf(
      <Document>
        <Page>
          <View style={styles.title}>
            <Text>Your Business QR code</Text>
          </View>
          <Image style={styles.image} src={qrCodeURI as string} />
        </Page>
      </Document>
    ).toBlob();

    saveAs(blob, "qrcode");
  };

  const copyLink = () => {
    navigator.clipboard.writeText(value).then(() => {
      setMessage("Link copied!");

      setTimeout(() => {
        setMessage("");
      }, 1000);
    });
  };

  return (
    <Grid
      container
      direction={"column"}
      alignItems={"center"}
      className={classes.container}
      spacing={2}
    >
      <Grid item>
        <Typography className={classes.title} component={"h2"} variant={"h5"}>
          Your Business QR code
        </Typography>
      </Grid>
      <Grid item>
        <QRCode value={value} />
      </Grid>
      <Grid item>
        <Box display={"flex"} flexDirection={"column"}>
          <Tooltip open={!!message} placement={"top-end"} title={message}>
            <Button
              onClick={copyLink}
              color="primary"
              variant="contained"
              endIcon={<LinkIcon />}
            >
              Copy link
            </Button>
          </Tooltip>
          <Button
            className={classes.button}
            color="primary"
            variant={"contained"}
            onClick={generatePDF}
          >
            Generate pdf with QR Code
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

// Create styles
const styles = StyleSheet.create({
  title: {
    marginTop: 10,
    textAlign: "center",
  },
  image: {
    marginTop: 50,
    marginHorizontal: 210,
  },
});

export default QrCode;
