import { createSlice } from "@reduxjs/toolkit";

import {
  getJoinLinkInfo,
  getTicket,
  joinLine,
  updateTicketInfo,
} from "../actions";

export type JoinLinkInfoType = {
  businessName?: string;
  serviceTime?: number;
  averageWaitingTime?: number;
  businessId?: string;
};

export type TicketInfoType = {
  id: string;
  firstName: string;
  lastName: string;
  createdAt: number;
  invitedAt?: number;
  confirmedAt?: number;
  canceledAt?: number;
  businessName: string;
  estimatedWaitingTime: number;
  phone: string;
  status: string;
  partySize: number;
};

type JoinSliceType = {
  joined: boolean;
  ticket?: TicketInfoType;
  joinLinkInfo?: JoinLinkInfoType;
};

const initialState: JoinSliceType = {
  joined: !!localStorage.getItem("ticketId"),
};

export const joinSlice = createSlice({
  name: "join",
  initialState,
  reducers: {
    setJoined: (state, action) => {
      state.joined = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getJoinLinkInfo.fulfilled,
      (state: JoinSliceType, action) => {
        state.joinLinkInfo = action.payload;
      }
    );
    builder.addCase(getTicket.fulfilled, (state: JoinSliceType, action) => {
      state.ticket = action.payload;
    });
    builder.addCase(joinLine.fulfilled, (state: JoinSliceType) => {
      state.joined = true;
    });
    builder.addCase(
      updateTicketInfo.fulfilled,
      (state: JoinSliceType, action) => {
        state.ticket = { ...state.ticket, ...action.payload } as TicketInfoType;
      }
    );
  },
});

export const { setJoined } = joinSlice.actions;

export default joinSlice.reducer;
