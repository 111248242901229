import { TicketInfoType } from "../../../store/reducers/join";
import { secondsToMinutes } from "../../../utils/utils";

export const getEstimatedWaitingTime = (
  averageWaitingTime: number,
  extraWaitingTime = 0
) => {
  const estimatedWaitingTime = extraWaitingTime + averageWaitingTime;

  return estimatedWaitingTime > 0 ? estimatedWaitingTime : 0;
};

export const getMyTicket = (tickets: Array<TicketInfoType>, ticketId: string) =>
  tickets.findIndex((ticket) => ticket.id === ticketId);

export const getTicketWaitingTime = (ticket: TicketInfoType) =>
  secondsToMinutes(Date.now() / 1000 - ticket?.createdAt);
