import firebase from "firebase";

import { TicketInfoType } from "../store/reducers/join";

export const noDelaySetInterval = (func: () => void, interval: number) => {
  func();
  return setInterval(func, interval);
};

export const convertTicketSnapshot = (
  doc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
) => {
  const data = doc.data();

  return {
    ...data,
    id: doc.id,
    createdAt: data.createdAt.seconds,
    invitedAt: data.invitedAt?.seconds,
    confirmedAt: data.confirmedAt?.seconds,
    canceledAt: data.canceledAt?.seconds,
  } as TicketInfoType;
};

export const secondsToMinutes = (sec: number) => Math.floor(sec / 60);
